import img1 from "../assents/contacts__img/img1.svg"
import img2 from "../assents/contacts__img/img.svg"
import img3 from "../assents/contacts__img/Group.svg"
export const ContactCardArr = [ 
    {
        img:img1,
        paragraph:"Zugspitzring 17 -1  ",
        adress:"89231  Neu-Ulm ",
      
    },
    {
        img:img2,
        paragraph:"Dr.Stepan Zenin",
        adress:"Email: Info@zess-consulting.de ",
        

    },
    {
        img:img3,
        paragraph:"Mobil: +49-174 8190712",
     
    }

]