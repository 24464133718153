import img1 from "../assents/team_img/img1.svg";
import img from "../assents/socials/inst.svg"
import img2 from "../assents/socials/telegram.svg"
import img3 from "../assents/socials/whatsapp.svg"
export const TeamArr = [
  {
    img: img1,
    Name: "TeamArr.Name",
    WhoIsIt: "TeamArr.WhoIsIt",
  }
]
export const TextArr = [
  {
    text: "team.text",
  },
  {
    text2:"About.text2",
    text3: "About.text3",
    text4:"About.text4",
    text5: "About.text5"
    
  },
  {
    text2: "About.text6",
    text3: "About.text7",
    text4:"About.text8",
    text5: "About.text9",
  },
  {
    text: "About.text10",
  },
];
export const Socials = [
  {
    img:img,
    link:"https://instagram.com/zess.consulting?igshid=Zm5jcnVreGptOGln"

  },

  {
    img:img2,
    link:"https://t.me/Zess_consulting"
  },
  {
    img:img3,
    link:"https://wa.me/4917638119214"
  }
]