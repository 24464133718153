import img1 from "../assents/number/Frame 41.svg"
import img2 from "../assents/number/Frame 42.svg"
import img3 from "../assents/number/Frame 43.svg"
import img4 from "../assents/number/Frame 44.svg"
import img5 from "../assents/number/Frame 45.svg"
import img6 from "../assents/number/Frame 46.svg"
import img7 from "../assents/number/Frame 47.svg"
 
export const ShemeArr = [
  {
    img: img1,
    title: "sheme.title1",
    text: "sheme.text1",
  },
  {
    img: img2,
    title: "sheme.title2",
    text: "sheme.text2",
  },
  {
    img: img3,
    title: "sheme.title3",
    text: "sheme.text3",
  },
  {
    img: img4,
    title: "sheme.title4",
    text: "sheme.text4",
  },
  {
    img: img5,
    title: "sheme.title5",
    text: "sheme.text5",
  },
  {
    img: img6,
    title: "sheme.title6",
    text: "sheme.text6",
  },
  {
    img: img7,
    title: "sheme.title7",
    text: "sheme.text7",
  },
];
export const SchemeText = [
  {
     title:"sheme.title8",
  },
  {
    title:"sheme.title9",
 },
 {
  title:"sheme.title10",
}
]
 
 
 
 
