import img1 from "../assents/personal/img1.svg"
import img2 from "../assents/personal/img2.svg"
import img3 from "../assents/personal/img3.svg"
import img4 from "../assents/personal/img4.svg"
import img5 from "../assents/personal/img5.svg"
export const RamText = [
  {
    text: "ram.text1",
  },
];
export const RamText2 = [
  {
    text: "ram.text2",
  },
];
export const RamText3 = [
  {
    text: "ram.text2",
  },
];
export const RamText4 = [
  {
    title: "ram.text3",
    text: "ram.text4",
  },
];
export const RamText5 = [
  {
    text: "ram.text5",
  },
];
export const RamText6 = [
  {
    text: "ram.text6",
    title: "ram.text7",
    title1: "ram.text8",
    title2: "ram.text9",
    title3: "ram.text10",
  },
];
export const RamText7 = [
  {
    text: "ram.text11",
  },
];
export const RamText8= [
  {
    text:"ram.text12",
  }
]
export const RamText9= [
  {
    text:"ram.text13",
  }
]
export const PicturesArr = [
  {
    Img:img1,
  },
  {
    Img:img2,
  },
  {
    Img:img3,
  },
  {
    Img:img4,
  },
  {
    Img:img5,
  },

]