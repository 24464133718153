import React, { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Suspense fallback={<div>Loading...</div>}>
    <App />
  </Suspense>
</React.StrictMode>
);

reportWebVitals();
